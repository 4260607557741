import React from "react"
import { ThemeProvider } from "styled-components"
import Layout from "src/components/layout"
import PrivacyPage from "src/components/privacy-page/privacy-page"
import { Banner } from "src/components/Banner"
import SEO from "src/components/seo"
import "src/components/common/custom-bluma.scss"
import { ClientHeader } from "src/components/Header"
import { darkTheme } from "../utils/themes"
import Container from "src/components/Container"

const PrivacyPolicyPage = ({ location }) => (
  <ThemeProvider theme={darkTheme}>
    <Layout>
      <SEO title="Privacy Policy" location={location} />
      <Banner style={{ overflow: "visible" }}>
        <ClientHeader />
      </Banner>
      <PrivacyPage />
    </Layout>
  </ThemeProvider>
)

export default PrivacyPolicyPage
