import React from "react"
import { Link } from "gatsby"

const PrivacyPage = () => (
  <>
    <div className="container">
      <br />
      <div className="column mb-6">
        <h1 className="is-size-2 has-text-primary has-text-centered has-text-weight-bold">
          Privacy Policy
        </h1>
        <p className="is-size-6 has-text-centered has-text-weight-semibold">
          Last Updated on: 06/15/2020
        </p>
        <br />
        <p className="is-size-5 has-text-weight-bold mt-5">Our Policy:</p>
        <p>
          We Welcome to the web site (the “Site”) of Archimydes, a Archimydes
          Inc product (“Archimydes”, “we”, “us” and/or “our”). This Site is
          operated by Archimydes and has been created to provide information
          about our company and our software-as-a-service platform for
          developing user stories and managing software development projects
          (together with the Site, the “Services”) to our Service visitors
          (“you”, “your”). This Privacy Policy sets forth Archimydes’ policy
          with respect to information including personally identifiable data
          (“Personal Data”) and other information that is collected from
          visitors to the Site and Services.
        </p>
        <p className="is-size-5 has-text-weight-bold mt-5">
          Information We Collect:
        </p>
        <p>
          <u>Information We Collect:</u> When you interact with us through the
          Site or the Services, we may collect Personal Data and other
          information from you, as further described below:
        </p>
        <p className="mt-4">
          <u>Personal Data That You Provide Through the Site:</u> We collect
          Personal Data from you when you voluntarily provide such information
          such as when you register for access to the Services (for example,
          your name and mailing address), use certain Services, contact us with
          inquiries or respond to one of our surveys. Wherever Archimydes
          collects Personal Data we make an effort to provide a link to this
          Privacy Policy.
        </p>
        <p className="mt-4">
          By voluntarily providing us with Personal Data, you are consenting to
          our use of it in accordance with this Privacy Policy. If you provide
          Personal Data, you acknowledge and agree that such Personal Data may
          be transferred from your current location to the offices and servers
          of Archimydes and the authorized third parties.
        </p>
        <p className="mt-4">
          <u>Non-Identifiable or Aggregated Data:</u> When you interact with
          Archimydes through the Site or Services, we receive and store certain
          personally non-identifiable information. Such information, which is
          collected passively using various technologies, cannot presently be
          used to specifically identify you. Archimydes may store such
          information itself or such information may be included in databases
          owned and maintained by Archimydes affiliates, agents or service
          providers. This Site may use such information and pool it with other
          information to track, for example, the total number of visitors to our
          Site, the number of visitors to each page of our Site, the domain
          names of our visitors' Internet service providers, and how our users
          use and interact with the Services. Also, in an ongoing effort to
          better understand and serve the users of the Services, Archimydes
          often conducts research on its customer demographics, interests and
          behavior based on the Personal Data and other information provided to
          us. This research may be compiled and analyzed on an aggregate basis.
          Archimydes may share this non-identifiable and aggregate data with its
          affiliates, agents and business partners, but this type of
          non-identifiable and aggregate information does not identify you
          personally. Archimydes may also disclose aggregated user statistics in
          order to describe our Services to current and prospective business
          partners, and to other third parties for other lawful purposes.
        </p>
        <p className="mt-4">
          In operating this Site, we may also use a technology called "cookies."
          A cookie is a piece of information that the computer that hosts our
          Site gives to your browser when you access the Site. Our cookies help
          provide additional functionality to the Site and help us analyze Site
          usage more accurately. For instance, our Site may set a cookie on your
          browser that allows you to access the Site without needing to remember
          and then enter a password more than once during a visit to the Site.
          In all cases in which we use cookies, we will not collect Personal
          Data except with your permission. On most web browsers, you will find
          a “help” section on the toolbar. Please refer to this section for
          information on how to receive notification when you are receiving a
          new cookie and how to turn cookies off. We recommend that you leave
          cookies turned on because they allow you to take advantage of some of
          the Site’s features.
        </p>
        <p className="mt-4">
          <u>Third Party Tracking and Analytics:</u> We may allow third party
          service providers to use cookies or similar technologies to collect
          information about your browsing activities over time and across
          different websites following your use of the Services. For example, we
          may use Google Analytics, a web analytics service provided by Google,
          Inc. (“Google”), and Mixpanel, a mobile and web analytics platform
          provided by Mixpanel, Inc. (“Mixpanel”). Google and Mixpanel use
          cookies or similar technologies to help us analyze how users function
          and use our mobile applications and the Site to improve our Service.
          We may also implement Google Analytics features based on Display
          Advertising (which may include Remarketing with Google Analytics).
          Visitors can opt out by using Google’s Ads Settings or going to
          tools.google.com/dlpage/gaoptout/. To opt-out of Mixpanel’s collection
          of your data, go to mixpanel.com/optout/. Please note that if you get
          a new computer, install a new browser, erase or otherwise alter your
          browser's cookie file (including upgrading certain browsers) you may
          also clear the Mixpanel opt-out cookie.
        </p>

        <p className="is-size-5 has-text-weight-bold mt-5">
          Our Use of Your Personal Data and Other Information:
        </p>
        <p>
          Archimydes uses the Personal Data you provide in a manner that is
          consistent with this Privacy Policy. If you provide Personal Data for
          a certain reason, we may use the Personal Data in connection with the
          reason for which it was provided. For instance, if you contact us by
          email, we will use the Personal Data you provide to answer your
          question or resolve your problem. Also, if you provide Personal Data
          in order to obtain access to the Services, we will use your Personal
          Data to provide you with access to such services and to monitor your
          use of such services. Archimydes and its subsidiaries and affiliates
          (the “Related Companies”) may also use your Personal Data and other
          personally non-identifiable information collected through the Site to
          help us improve the content and functionality of the Site, to better
          understand our users and to improve the Services. Archimydes and its
          Related Companies may use this information to communicate with you
          regarding the Services or to tell you about services we believe will
          be of interest to you. If we do so, each marketing communication we
          send you will contain instructions permitting you to "opt-out" of
          receiving future marketing communications. Note however that as user
          of the Service you cannot opt-out of some administrative
          communications that are reasonably necessary to the Service, such as
          billing or service notifications. In addition, if at any time you wish
          not to receive any future marketing communications or you wish to have
          your name deleted from our mailing lists, please contact us as
          indicated below.
        </p>
        <p className="mt-4">
          If Archimydes intends on using any Personal Data in any manner that is
          not consistent with this Privacy Policy, you will be informed of such
          anticipated use prior to or at the time at which the Personal Data is
          collected.
        </p>

        <p className="is-size-5 has-text-weight-bold mt-5">
          Our Disclosure of Your Personal Data and Other Information:
        </p>
        <p>
          Archimydes is not in the business of selling your information. We
          consider this information to be a vital part of our relationship with
          you. There are, however, certain circumstances in which we may share
          your Personal Data with certain third parties without further notice
          to you, as set forth below:
        </p>
        <p className="mt-4">
          <u>Business Transfers:</u> As we develop our business, we might sell
          or buy businesses or assets. In the event of a corporate sale, merger,
          reorganization, dissolution or similar event, Personal Data may be
          part of the transferred assets.
        </p>
        <p className="mt-4">
          <u>Related Companies:</u> We may also share your Personal Data with
          our Related Companies for purposes consistent with this Privacy
          Policy.
        </p>
        <p className="mt-4">
          <u>Agents, Consultants and Related Third Parties:</u> Archimydes, like
          many businesses, sometimes hires other companies to perform certain
          business-related functions. Examples of such functions include mailing
          information, maintaining databases, billing and processing payments.
          When we employ another company to perform a function of this nature,
          we only provide them with the information that they need to perform
          their specific function.
        </p>
        <p className="mt-4">
          <u>Legal Requirements:</u> Archimydes may disclose your Personal Data
          if required to do so by law or in the good faith belief that such
          action is necessary to (i) comply with a legal obligation, (ii)
          protect and defend the rights or property of Archimydes, (iii) act in
          urgent circumstances to protect the personal safety of users of the
          Services or the public, or (iv) protect against legal liability.
        </p>

        <p className="is-size-5 has-text-weight-bold mt-5">Your Choices:</p>
        <p>
          You can visit the Site without providing any Personal Data. If you
          choose not to provide any Personal Data, you may not be able to use
          certain Services.
        </p>

        <p className="is-size-5 has-text-weight-bold mt-5">Exclusions:</p>
        <p>
          This Privacy Policy does not apply to any Personal Data collected by
          Archimydes other than Personal Data collected through the Site or
          Services. This Privacy Policy shall not apply to any unsolicited
          information you provide to Archimydes through this Site or through any
          other means. This includes, but is not limited to, information posted
          to any public areas of the Site, such as forums (collectively, “Public
          Areas”), any ideas for new products or modifications to existing
          products, and other unsolicited submissions (collectively,
          “Unsolicited Information”). All Unsolicited Information shall be
          deemed to be non-confidential and Archimydes shall be free to
          reproduce, use, disclose, distribute and exploit such Unsolicited
          Information without limitation or attribution.
        </p>

        <p className="is-size-5 has-text-weight-bold mt-5">Children:</p>
        <p>
          Archimydes does not knowingly collect Personal Data from children
          under the age of 13. If you are under the age of 13, please do not
          submit any Personal Data through the Services. We encourage parents
          and legal guardians to monitor their children’s Internet usage and to
          help enforce our Privacy Policy by instructing their children never to
          provide Personal Data on the Services without their permission. If you
          have reason to believe that a child under the age of 13 has provided
          Personal Data to Archimydes through the Services, please contact us,
          and we will endeavor to delete that information from our databases.
        </p>

        <p className="is-size-5 has-text-weight-bold mt-5">
          Links to Other Web Sites:
        </p>
        <p>
          This Privacy Policy applies only to the Services. The Services may
          contain links to other web sites not operated or controlled by
          Archimydes (the “Third Party Sites”). The policies and procedures we
          described here do not apply to the Third Party Sites. The links from
          the Services do not imply that Archimydes endorses or has reviewed the
          Third Party Sites. We suggest contacting those sites directly for
          information on their privacy policies.
        </p>

        <p className="is-size-5 has-text-weight-bold mt-5">Security:</p>
        <p>
          Archimydes takes reasonable steps to protect the Personal Data
          provided via the Services from loss, misuse, and unauthorized access,
          disclosure, alteration, or destruction. However, no Internet or email
          transmission is ever fully secure or error free. In particular, email
          sent to or from the Services may not be secure. Therefore, you should
          take special care in deciding what information you send to us via
          email. Please keep this in mind when disclosing any Personal Data to
          Archimydes via the Internet.
        </p>

        <p className="is-size-5 has-text-weight-bold mt-5">
          Other Terms and Conditions:
        </p>
        <p>
          Your access to and use of the Services is subject to the Terms of
          Service at Archimydes.com/terms-of-use.
        </p>

        <p className="is-size-5 has-text-weight-bold mt-5">
          Access to Information; Contacting Archimydes Policy:
        </p>
        <p>
          To keep your Personal Data accurate, current, and complete, please
          contact us as specified below. We will take reasonable steps to update
          or correct Personal Data in our possession that you have previously
          submitted via this Site or Services. Please also feel free to contact
          us if you have any questions about Archimydes’s Privacy Policy or the
          information practices of this Site.
        </p>
        <p className="mt-4">
          You may contact us as follows: <b>privacy@archimydes.com</b>
        </p>
      </div>
    </div>
  </>
)

export default PrivacyPage
